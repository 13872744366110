import {
    CHANGE_MEMBER_ROLE_CALL,
    GET_MEMBER_ROLES_SUCCESS,
    INVITATION_DELETE_CALL,
    INVITE_MEMBER_CALL,
    MEMBER_DELETE_CALL,
    MEMBER_LOADING_OFF,
    MEMBERS_CALL_SUCCESS, MEMBERS_DATA_CALL,
    RESET_AFFILIATION_MEMBERS_STORE,
    SET_ACTIVE_PARENT_CUSTOMER,
    SET_PARENT_CUSTOMERS,
    UPDATE_MEMBER_DATA
} from './actions';

const initialState = {
    total: 0,
    count: 0,
    members: [],
    invitations: [],
    memberRoles: [],
    parentCustomers: [],
    activeParentCustomer: undefined,
    activeParentCustomerId: '',
    loading: false,
    isFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {
        case GET_MEMBER_ROLES_SUCCESS: {
            newState.memberRoles = payload.memberRoles;
            return newState;
        }

        case INVITE_MEMBER_CALL: {
            newState.loading = true;
            return newState;
        }

        case MEMBERS_DATA_CALL:
        case MEMBER_DELETE_CALL:
        case INVITATION_DELETE_CALL:
        case CHANGE_MEMBER_ROLE_CALL: {
            newState.loading = true;
            return newState;
        }

        case MEMBERS_CALL_SUCCESS: {
            const { members, invitations } = payload;
            const membersData = [...members, ...invitations];
            membersData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            newState.members = membersData;
            newState.isFetched = true;
            return newState;
        }

        case MEMBER_LOADING_OFF: {
            newState.loading = false;
            newState.isFetched = true;
            return newState;
        }

        case RESET_AFFILIATION_MEMBERS_STORE: {
            if (payload.isReset) {
                newState.members = [];
                newState.invitations = [];
                newState.memberRoles = [];
                newState.parentCustomers = [];
                newState.activeParentCustomer = undefined;
                newState.activeParentCustomerId = '';
                sessionStorage.removeItem('selectedParentCustomerId');
            }
            newState.isFetched = false;
            return newState;
        }

        case UPDATE_MEMBER_DATA: {
            const { member } = payload;
            newState.members = newState.members.map(mem => {
                if (mem.id === member.id) {
                   return member;
                }
                return mem;
            });
            return newState;
        }

        case SET_PARENT_CUSTOMERS: {
            newState.parentCustomers = payload.parentCustomers;
            return newState;
        }

        case SET_ACTIVE_PARENT_CUSTOMER: {
            newState.activeParentCustomer = payload.activeParentCustomer;
            return newState;
        }

        default:
            return state;
    }
};